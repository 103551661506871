// methods
import { getLessonList, delLesson } from '@/api/basic';
// components
import { Button, Table, TableColumn } from 'element-ui';
import basicHeader from '@/components/backStageHeader';
import qlPagination from '@/components/qlPagination';
import subjectDialog from './components/subjectDialog';

export default {
  name: 'subject',
  props: {},
  data() {
    return {
      loading: false,
      listData: {
        list: [],
        pageSize: 10,
        pageIndex: 1,
        total: 0,
      },
      isEdit: false,
      editIndex: -1,
      visible: false,

      dialogData: {
        lessonId: null,
        subjectName: '',
      },
    };
  },
  components: {
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    basicHeader,
    qlPagination,
    subjectDialog,
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
    notAllowOperate() {
      return this.userInfo.thirdpartySchoolProjectCode !== '';
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getLessonList();
    },
    getLessonList(reset = false) {
      let { pageIndex, pageSize } = this.listData;

      if (reset) pageIndex = this.listData.pageIndex = 1;

      this.loading = true;

      getLessonList({
        pageIndex,
        pageSize,
      })
        .then(
          (res) => {
            let { recordCount, data } = res.result;
            this.listData.total = recordCount;
            this.listData.list = data || [];
          },
          (err) => console.log(err)
        )
        .finally(() => {
          this.loading = false;
        });
    },

    handleAddNew() {
      if (this.notAllowOperate) return;

      this.isEdit = false;
      this.dialogData = {
        lessonId: null,
        subjectName: '',
      };
      this.visible = true;
    },

    handleEdit(val, index) {
      if (this.notAllowOperate) return;

      const { lessonId, lessonName } = val;
      this.dialogData = {
        lessonId: lessonId,
        subjectName: lessonName,
      };
      this.isEdit = true;
      this.editIndex = index;
      this.visible = true;
    },

    handleSuccess(item) {
      if (this.isEdit && this.editIndex >= 0) {
        this.listData.list[this.editIndex].lessonName = item.subjectName;
      } else {
        this.getLessonList(true);
      }
      this.visible = false;
    },

    handleDel(val) {
      if (this.notAllowOperate) return;

      this.$msgbox
        .confirm('你确定删除学科信息吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(() => {
          delLesson({
            lessonId: val.lessonId,
          }).then((res) => {
            if (res && res.status === 0) {
              let idx = this.listData.pageIndex;
              if (idx > 1 && this.listData.list && this.listData.list.length === 1) {
                idx--;
              }
              this.handleCurrentChange(idx);
            }
          });
        });
    },

    handleSizeChange(val) {
      this.listData.pageSize = val;
      this.getLessonList(true);
    },
    handleCurrentChange(val) {
      this.listData.pageIndex = val;
      this.getLessonList();
    },

    // 批量录入
    handleImport() {
      if (this.notAllowOperate) return;

      this.$batchEntry({
        type: 'subject',
        uploadTip: '数据总量不得超过100条',
        callback: () => {
          this.getLessonList(true);
        },
      });
    },

    // 批量导出
    handleExport() {
      this.$batchExport({
        type: 'subject',
      });
    },
  },
};

<template>
  <el-dialog
    custom-class="ql-dialog subjectDialog"
    :title="isEdit ? '编辑学科' : '新增学科'"
    :visible.sync="visible"
    width="580px"
  >
    <el-form ref="form" :model="formData" :rules="rules" label-width="85px">
      <el-form-item label="学科名称" prop="subjectName" size="small">
        <el-input placeholder="请输入学科名称" v-model.trim="formData.subjectName"> </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="handleCancel" size="small">取 消</el-button>
      <el-button type="primary" @click="handleAdd" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { Button, Dialog, Form, FormItem, Input } from 'element-ui';
import { saveLesson } from '@/api/basic';

export default {
  name: 'SubjectDialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    dialogData: Object,
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const validateSubjectName = (rule, value, callback) => {
      if (!value) {
        callback(new Error('学科名称不能为空'));
        return;
      } else if (value.length > 25) {
        callback(new Error('学科名称不能超过25位'));
        return;
      }
      callback();
    };
    return {
      rules: {
        subjectName: [
          {
            required: true,
            validator: validateSubjectName,
            trigger: 'blur',
          },
        ],
      },
      formData: { lessonId: null, subjectName: '' },
    };
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.formData = this.dialogData;
      }
    },
  },
  components: {
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
  },
  methods: {
    handleAdd() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          saveLesson(
            {
              lessonName: this.formData.subjectName,
              lessonId: this.isEdit ? this.formData.lessonId : undefined,
            },
            this.isEdit
          ).then(
            (res) => {
              if (res && res.result === 0) {
                this.$emit('success', this.formData);
              } else {
                this.$message.error(res.msg);
              }
            },
            (err) => {
              console.log(err);
            }
          );
        }
        return false;
      });
    },

    handleCancel() {
      this.visible = false;
      this.$refs.form.clearValidate();
    },
  },
};
</script>

<style lang="scss" scoped>
.subjectDialog {
}
</style>
